<template>
  <div>
    <div class="cui__utils__heading mb-0">
      <strong>EDIT EMAIL</strong>
    </div>
    <hr />
    <a-form @submit.prevent="changeEmail">
      <a-form-item
        label="Email"
        :validate-status="validate.status"
        :has-feedback="isValid"
        :help="validate.help"
      >
        <a-input size="large" v-model="currentEmail" />
      </a-form-item>
      <a-button
        class="btnChange"
        type="primary"
        html-type="submit"
        size="large"
        :loading="loadingEmailChange"
        >Change Email</a-button
      >
    </a-form>
  </div>
</template>

<script>
export default {
  name: 'EditEmail',
  props: {
    email: {
      type: String,
    },
    loadingEmailChange: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      currentEmail: '',
      validate: {
        status: '',
        help: '',
      },
    }
  },
  mounted() {
    this.currentEmail = this.email
  },
  methods: {
    changeEmail() {
      this.$emit('on-change-email', this.currentEmail)
    },
  },
}
</script>

<style>
</style>
