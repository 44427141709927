<template>
  <div class="personal-info">
    <div class="cui__utils__heading mb-0">
      <strong>PERSONAL INFORMATION</strong>
    </div>
    <hr />
    <!-- {{ userData }} -->
    <a-alert
      v-if="userData.email && userData.status_email === 'unverified'"
      message="Warning"
      type="warning"
      show-icon
      class="my-4"
    >
      <p v-if="firstClick" slot="description">
        Your email has not been verified,
        <a @click.prevent="verifyEmail" class="text-primary"
          >please use this link to verify your account.</a
        >
      </p>
      <p v-else slot="description">
        Your link verification has been disabled for 15s from now, Try again
        later.
      </p>
    </a-alert>
    <a-row :gutter="[32, 16]">
      <a-col :sm="24" :lg="12">
        <a-form-item v-if="role === 'Teacher'" label="NIK">
          <a-input size="large" :value="userData.nik" :disabled="true" />
        </a-form-item>
        <a-form-item v-else-if="role === 'Student'" label="NIS">
          <a-input size="large" :value="userData.nis" :disabled="true" />
        </a-form-item>
      </a-col>
      <a-col :sm="24" :lg="12">
        <a-form-item label="Name">
          <a-input size="large" :value="userData.nama" :disabled="true" />
        </a-form-item>
      </a-col>
      <a-col :sm="24" :md="24" :lg="12">
        <a-form-item label="Gender">
          <a-radio-group
            class="radio-gender w-100"
            :value="userData.jenis_kelamin"
            size="large"
            button-style="solid"
          >
            <a-radio-button
              value="Laki-Laki"
              class="w-50 text-center"
              :class="
                userData.jenis_kelamin === 'Laki-Laki'
                  ? 'bg-primary border border-primary'
                  : ''
              "
              >Laki-Laki</a-radio-button
            >
            <a-radio-button
              value="Perempuan"
              class="w-50 text-center"
              :class="
                userData.jenis_kelamin === 'Perempuan'
                  ? 'bg-primary border border-primary'
                  : ''
              "
              >Perempuan</a-radio-button
            >
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :sm="24" :lg="12">
        <a-form-item label="Email">
          <a-input-search size="large" :value="userData.email" :disabled="true">
            <a-button
              v-if="userData.status_email === 'verified'"
              type="primary"
              icon="check-circle"
              slot="enterButton"
            >
              Verified
            </a-button>
            <a-button v-else icon="minus-circle" slot="enterButton">
              Unverified
            </a-button>
          </a-input-search>
        </a-form-item>
      </a-col>
      <a-col :sm="24" :lg="24">
        <a-form-item label="Address">
          <a-textarea
            :value="userData.alamat"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :disabled="true"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'PersonalInformation',
  props: {
    userData: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    firstClick: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    verifyEmail() {
      this.$emit('click-verify')
    },
  },
}
</script>

<style lang="scss">
// .personal-info {
//   .radio-gender {
//     width: 100;
//   }
// }
</style>
