<template>
  <div class="card-full">
    <div class="card-detail">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
}
</script>

<style lang="scss">
.card-full {
  padding: 20px 20px;
  // margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
  .card-detail {
    padding: 15px;
  }
}
</style>
