<template>
  <div>
    <div class="cui__utils__heading mb-0">
      <strong>EDIT PASSWORD</strong>
    </div>
    <hr />
    <a-row class="row-password" type="flex" justify="center">
      <a-form @submit.prevent="changePassword">
        <a-col :span="24">
          <a-form-item label="Current Password">
            <a-input-password size="large" v-model="password.currentPassword" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="New Password">
            <a-input-password size="large" v-model="password.newPassword" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="Confirm New Password">
            <a-input-password
              size="large"
              v-model="password.confirmNewPassword"
            />
          </a-form-item>
        </a-col>
        <a-button
          class="btnChange"
          type="primary"
          html-type="submit"
          size="large"
          >Change Password</a-button
        >
      </a-form>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'EditPassword',
  props: {
    password: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changePassword() {
      this.$emit('on-change-password', this.password)
    },
  },
}
</script>

<style>
</style>
