<template>
  <a-menu mode="vertical" :selected-keys="selectedMenu" @click="handleClick">
    <a-menu-item v-for="menu in menus" :key="menu.key">
      <a-icon :type="menu.icon" />
      <span>{{ menu.name.toUpperCase() }}</span>
    </a-menu-item>
  </a-menu>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    menus: {
      type: Array,
      required: true,
    },
    selectedMenu: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('menu-change', e)
    },
  },
}
</script>

<style>
</style>
